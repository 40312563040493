import React, { useState, useContext, useEffect } from "react";
import { useTranslation } from "next-i18next";
import { FirebaseContext } from "./Firebase";
import ImageUploading from "react-images-uploading";
import { v4 as uuidv4 } from "uuid";
import { LayoutConditionsDisplay } from './LayoutConditionsDisplay'

export const ImageUpload = ({ 
  onImageAdded = () => { }, 
  handleChange, 
  isOwner, 
  moodBoard, 
  setCustomInputValue, 
  setCustomInput, 
  setIsRewriting, 
  setIsCustomInputFocused,
  setLayoutConditions = () => {}
}) => {
  const fb = useContext(FirebaseContext);
  const [clipBoardInfo, setClipBoardInfo] = useState(null);
  const { uploadUserInputImages, app, user } = fb;
  const { t } = useTranslation();
  const [image, setImage] = useState(null);
  const [layoutDescription, setLayoutDescription] = useState(null);
  const [designSuggestions, setDesignSuggestions] = useState(null);
  const [layoutConditions, setLayoutConditionsLocal] = useState(null);
  const [isAnalyzing, setIsAnalyzing] = useState(false);

  useEffect(() => {
    if (isOwner && moodBoard?.imageUpload) {
      setImage(moodBoard.imageUpload);
      onImageAdded(moodBoard.imageUpload);
      
      if (moodBoard.layoutDescription && moodBoard.designSuggestions) {
        setLayoutDescription(moodBoard.layoutDescription);
        setDesignSuggestions(moodBoard.designSuggestions);
      } else if (moodBoard.gptPrompt) {
        setLayoutDescription(moodBoard.gptPrompt);
        setDesignSuggestions("");
      }
    }
  }, [isOwner, moodBoard]);

  const onChange = async (imageList, addUpdateIndex) => {
    setImage(null);
    setLayoutDescription(null);
    setDesignSuggestions(null);
    setLayoutConditionsLocal(null);
    setClipBoardInfo(t('image-upload.success'));
    setIsAnalyzing(true);

    const id = uuidv4();
    const image = await uploadUserInputImages(id, imageList[0].file);
    
    const docRef = app.firestore().collection("inputPhotos").doc(id);
    await docRef.set({
      imageUpload: image,
      uid: user.uid,
      created: app.firestore.FieldValue.serverTimestamp(),
    })
    setIsRewriting(true);
    setIsCustomInputFocused(true);

    docRef.onSnapshot((doc) => {
      if (doc.exists) {
        const data = doc.data();
        if (data.layoutDescription && data.designSuggestions) {
          const combinedDescription = `${data.layoutDescription}\n\n ${data.designSuggestions}`;
          setCustomInputValue(combinedDescription);
          setCustomInput(combinedDescription);
          setIsRewriting(false);
          
          if (data.layoutConditions) {
            setLayoutConditions(data.layoutConditions);
            setLayoutConditionsLocal(data.layoutConditions);
          }
          setIsAnalyzing(false);
        }
      }
    });

    setImage(image);
    onImageAdded(image);
    setClipBoardInfo(null);
  };

  return (
    <>
      {clipBoardInfo && (
        <p
          className={`border mt-3 p-3 text-base flex items-center justify-center rounded-lg ${clipBoardInfo.startsWith("Ooops")
              ? "border-red-900 text-red-900"
              : ""}`}
        >
          {clipBoardInfo}
        </p>
      )}
      <div className="flex flex-col gap-6 my-5">
        {image && (
          <span
            className={`relative cursor-pointer flex items-center rounded-xl ring-4 ring-pink-600`}
          >
            {isAnalyzing && (
              <div className="absolute inset-0 bg-white bg-opacity-70 rounded-xl flex items-center justify-center z-10">
                <div className="flex flex-col items-center text-gray-800">
                  <svg className="animate-spin h-8 w-8 mb-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  <span className="text-sm font-medium">Analyzing image...</span>
                </div>
              </div>
            )}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="absolute w-10 h-10 bottom-3 right-3 text-pink-600"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                fill="#ffffff" />
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clipRule="evenodd" />
            </svg>
            <img src={image} className="max-h-72 max-w-72 rounded-xl min-h-[200px] min-w-[200px]" />
          </span>
        )}
        <div className="mb-8">
          <p className="text-sm text-gray-500 mb-2 text-center">Redesign your room or discover designs inspired by your photo</p>
          <ImageUploading onChange={(imageList) => handleChange(onChange, imageList)} maxNumber={1}>
            {({
              onImageUpload, onImageRemoveAll, onImageUpdate, onImageRemove, isDragging, dragProps,
            }) => (
              <div
                onClick={onImageUpload}
              className={`flex items-center justify-center bg-pink-100 border-2 text-pink-800 rounded-xl focus:outline-none hover:opacity-70 ${isDragging
                  ? "animate-pulse border-solid border-pink-800"
                  : "border-dashed border-pink-300"}`}
              >
              <a
                className="text-lg text-center lg:mx-0 px-4 py-3  focus:outline-none"
                {...dragProps}
              >
                {t("image-upload.upload")}
                </a>
              </div>
            )}
          </ImageUploading>
          <p className="text-sm text-gray-500 mt-2 text-center">Skip upload to create an inspiration photo from text.</p>
        </div>
      </div>
        {image && layoutConditions && (
          <div className="">
            <LayoutConditionsDisplay conditions={layoutConditions} />
          </div>
        )}

    </>
  );
};
