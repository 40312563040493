import React from "react"
import Image from 'next/future/image'

const HowItWorks = () => {
  const steps = [
    {
      number: "1",
      title: "Start with Your Vision",
      description: "Begin by defining your creative vision—whether it's for a room, a brand, or an event. Lay the foundation for your stunning design journey.",
      image: "https://static.shuffle.dev/uploads/files/29/29ed9324f34ff57161e62e45b1e365a6c2cf9f02/Mood-Board-AI2.png",
      hasButton: true
    },
    {
      number: "2",
      title: "Choose Your Style",
      description: "Select an aesthetic—modern, vintage, or minimalist—and choose color palettes or layouts that inspire you.",
      image: "https://static.shuffle.dev/uploads/files/29/29ed9324f34ff57161e62e45b1e365a6c2cf9f02/Mood-Board-AI4.png"
    },
    {
      number: "3",
      title: "Add Custom Details",
      description: "Personalize your board with unique touches like colors, or board layout.",
      image: "https://static.shuffle.dev/uploads/files/29/29ed9324f34ff57161e62e45b1e365a6c2cf9f02/Mood-Board-AI3.png",
      hasButton: true
    },
    {
      number: "4",
      title: "Chat and Personalize",
      description: "Customize your dream mood board by describing your wishes and clicking 'Change prompt.' The AI will update the prompt and bring your ideas to life in the design. Click the 'Submit' button to generate your design.",
      image: "https://static.shuffle.dev/uploads/files/29/29ed9324f34ff57161e62e45b1e365a6c2cf9f02/Mood-Board-AI9.png"
    },
    {
      number: "5",
      title: "Refine Your Mood Board",
      description: "Effortlessly clean up, upscale your design, or discover real-life products inspired by your generated mood board.",
      image: "https://static.shuffle.dev/uploads/files/29/29ed9324f34ff57161e62e45b1e365a6c2cf9f02/Mood-Board-AI11.png",
      hasButton: true
    },
    {
      number: "6",
      title: "Discover Real-Life Products",
      description: "Integrate curated product suggestions seamlessly to bring your mood board to life.",
      image: "https://static.shuffle.dev/uploads/files/29/29ed9324f34ff57161e62e45b1e365a6c2cf9f02/Mood-Board-AI12.png"
    },
    {
      number: "7",
      title: "Add to Mood Board Editor",
      description: <>Seamlessly import your AI-generated board into the <a href="https://moodboardai.com/new-board" className="text-pink-700 hover:text-pink-800 font-black">Mood Board Editor</a> to customize further, add real-life products, and create a shopping list.</>,
      video: "/assets/mood-board-videos/Add-Mood-Board-to-Mood-Board-Editor.mp4"
    },
    {
      number: "8",
      title: "Add Products to Create Shopping List",
      description: "After adding your design to the Mood Board Editor, easily add products, create a shareable shopping list, and refine items by removing backgrounds, cropping, flipping, or duplicating.",
      image: "https://static.shuffle.dev/uploads/files/29/29ed9324f34ff57161e62e45b1e365a6c2cf9f02/Mood-Board-AI17.png"
    },
    {
      number: "9",
      title: "Transform Your Mood Board into 3D",
      description: <>Upload your mood board to the <a href="/interior-design-ai/designer" className="text-pink-700 hover:text-pink-800 font-black">MyRoomDesigner.AI</a> tool and transform it into a 3D design. Simply upload the photo, select the "Inspiration" button, wait for the AI to analyze your photo, and then click "Generate."</>,
      image: "https://static.shuffle.dev/uploads/files/29/29ed9324f34ff57161e62e45b1e365a6c2cf9f02/Mood-Board-AI21.png",
      hasButton: true
    }
  ]

  const renderMedia = (step) => {
    if (step.video) {
      return (
        <div className="relative w-full rounded-xl shadow-xl overflow-hidden">
          <video className="w-full rounded-xl object-cover" loop muted autoPlay>
            <source src={step.video} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      )
    }
    
    const imageClasses = step.number === "8" || step.number === "9"
      ? "w-full object-cover"
      : "w-full shadow-xl object-cover rounded-xl"
    
    return (
      <Image
        src={step.image}
        alt={`Step ${step.number}`}
        width={2000}
        height={2000}
        className={imageClasses}
      />
    )
  }

  return (
    <section className="py-20" id="how-moodboard-ai-works">
      <div className="container mx-auto px-6 lg:px-20">
        {/* Header Section */}
        <div className="text-center mb-16">
          <span className="inline-block py-1 px-3 mb-4 text-xs uppercase rounded-full bg-pink-100 text-[#9f154d]">
            How it works
          </span>
          <h2 className="text-4xl md:text-5xl font-heading text-gray-900 leading-tight mb-6">
            Step by Step to Your Stunning Mood Board
          </h2>
          <p className="text-lg text-gray-600 mx-auto max-w-4xl">
            Follow these clear and simple steps to bring your creative ideas to life with Mood Board AI.
          </p>
        </div>

        {/* Steps Section */}
        <div className="space-y-24 pb-20">
          {steps.map((step, index) => (
            <React.Fragment key={index}>
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
                <div>
                  {renderMedia(step)}
                </div>
                <div className="lg:w-2/3 lg:justify-center lg:mx-auto">
                  <span className="block text-sm uppercase font-medium tracking-wide text-gray-400 mb-2 lg:justify-center lg:mx-auto">
                    Step {step.number}
                  </span>
                  <h3 className="text-3xl font-serif text-gray-900 mb-4 lg:justify-center lg:mx-auto">
                    {step.title}
                  </h3>
                  <p className="font-heading lg:justify-center lg:mx-auto">
                    {step.description}
                  </p>
                  {step.hasButton && (
                    <a 
                      href="/ai-generator" 
                      className="inline-block group relative font-heading mt-4 py-2 px-5 text-sm sm:text-base rounded-full cursor-pointer font-heading hover:ring-2 ring-gray-700 hover:ring-gray-500 ring-1 bg-[#fdfdfc]"
                    >
                      <p>Start to create now</p>
                    </a>
                  )}
                </div>
              </div>
              {index < steps.length - 1 && (
                <div className="border-t border-[#f7f6f4]"></div>
              )}
            </React.Fragment>
          ))}
        </div>
      </div>
    </section>
  )
}

export default HowItWorks 