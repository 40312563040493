import React, { useState, useEffect } from "react"
import { appWithTranslation } from "next-i18next"
import "../styles/globals.css"
import Script from "next/script"
import LoadingScreen from "../src/components/LoadingScreen"
import { useRouter } from 'next/router'

const App = ({ Component, pageProps }) => {
  const [loading, setLoading] = useState(false)
  const router = useRouter()

  useEffect(() => {
    const handleStart = () => setLoading(true)
    const handleComplete = () => setLoading(false)

    router.events.on('routeChangeStart', handleStart)
    router.events.on('routeChangeComplete', handleComplete)
    router.events.on('routeChangeError', handleComplete)

    return () => {
      router.events.off('routeChangeStart', handleStart)
      router.events.off('routeChangeComplete', handleComplete)
      router.events.off('routeChangeError', handleComplete)
    }
  }, [router])

  return (
    <>
      {loading && <LoadingScreen />}
      <Component {...pageProps} />
      <Script src="https://r.wdfl.co/rw.js" data-rewardful="f7a67e"></Script>
      <Script id="rewardful-queue" strategy="beforeInteractive">
        {`(function(w,r){w._rwq=r;w[r]=w[r]||function(){(w[r].q=w[r].q||[]).push(arguments)}})(window,'rewardful');`}
      </Script>
    </>
  )
}

export default appWithTranslation(App)
